import './webpack-imports';
import './sentry';
import './polyfills';
import './custom-elements';

import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import barba from '@barba/core';
import Swiper, { Navigation, Autoplay } from 'swiper';
import calculateScrollbarWidth from '../modules/calculate-scrollbar-width';
import { initBaseView } from './views/_base';
import lazyView from './views/_lazy';
import { CustomEase } from 'gsap/CustomEase';
import ajaxForms from './inits/ajax-forms';
import inputMasks from './inits/masks';
import contactForm from './inits/contact-form';
import preloader from './components/preloader';

// Transitions
import defaultTransition from './transitions/default.js';
import indexPageTransition from './transitions/index';
import aboutPageTransition from './transitions/about';
import trademarkPageTransition from './transitions/trademark.js';
import productsPageTransition from './transitions/products.js';
import productsDetailPageTransition from './transitions/product-detail.js';
import contactsPageTransition from './transitions/contacts.js';
import workingPageTransition from './transitions/working.js';

document.documentElement.classList.add('js-ready');

Swiper.use([Navigation, Autoplay]);

document.addEventListener(
    'preloader.complete',
    () => {
        calculateScrollbarWidth();

        gsap.config({ nullTargetWarn: false });
        gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, CustomEase);

        initBaseView();

        const requestPopupEl = document.querySelector('app-lit-popup[data-lit-popup="request"]');

        if (requestPopupEl) {
            ajaxForms.init(requestPopupEl);
            inputMasks.init(requestPopupEl);
        }
        barba.init({
            transitions: [
                defaultTransition,
                indexPageTransition,
                aboutPageTransition,
                trademarkPageTransition,
                productsPageTransition,
                productsDetailPageTransition,
                contactsPageTransition,
                workingPageTransition,
            ],
            views: [
                lazyView('index-page', () =>
                    import(/* webpackChunkName: "index-page", webpackPrefetch: true */ './views/index').then(
                        (m) => m.default,
                    ),
                ),
                lazyView('mark-of-care-page', () =>
                    import(/* webpackChunkName: "mark-of-care-page" */ './views/mark-of-care').then((m) => m.default),
                ),
                lazyView('about-page', () =>
                    import(/* webpackChunkName: "about-page" */ './views/about').then((m) => m.default),
                ),
                lazyView('error-page', () =>
                    import(/* webpackChunkName: "error-page" */ './views/error').then((m) => m.default),
                ),
                lazyView('trademark-page', () =>
                    import(/* webpackChunkName: "trademark-page" */ './views/trademark').then((m) => m.default),
                ),
                lazyView('working-page', () =>
                    import(/* webpackChunkName: "working-page" */ './views/working').then((m) => m.default),
                ),
                lazyView('products-page', () =>
                    import(/* webpackChunkName: "products-page" */ './views/products').then((m) => m.default),
                ),
                lazyView('product-detail-page', () =>
                    import(/* webpackChunkName: "product-detail-page" */ './views/product-detail').then(
                        (m) => m.default,
                    ),
                ),
                lazyView('contacts-page', () =>
                    import(/* webpackChunkName: "contacts-page" */ './views/contacts').then((m) => m.default),
                ),
            ],
            prefetchIgnore: true,
            preventRunning: true,
            timeout: 5000,
            requestError: (trigger, action, url, response) => {
                if (response.status === 404) {
                    if (window.location.pathname.includes('en/')) {
                        barba.go(`${ROOT_PATH}en/404/`);
                    } else {
                        barba.go(`${ROOT_PATH}404/`);
                    }
                }
                return false;
            },
            debug: NODE_ENV === 'development',
        });
    },
    {
        once: true,
    },
);
preloader.init();
contactForm.init();
if (history.scrollRestoration) {
    history.scrollRestoration = 'manual';
}
window.scrollTo({ top: 0, behavior: 'auto' });
