let k = window.matchMedia('(min-width: 1024px)').matches ? '-10' : '0';

const revealItemsObserver = new IntersectionObserver(
    (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                if (entry.target.dataset.revealRepeat !== 'true') {
                    revealItemsObserver.unobserve(entry.target);
                }

                entry.target.classList.add('is-revealed');
            }

            if (entry.target.dataset.revealRepeat === 'true') {
                if (!entry.isIntersecting) {
                    entry.target.classList.remove('is-revealed');
                }
            }
        });
    },
    {
        rootMargin: `${k}%`,
    },
);

function onResize() {
    k = window.matchMedia('(min-width: 1024px)').matches ? '-10' : '0';
}

function init(container = document) {
    Array.from(container.querySelectorAll('.js-reveal')).forEach((el) => {
        revealItemsObserver.observe(el);
    });
    window.addEventListener('resize', onResize);
}

function destroy(container = document) {
    Array.from(container.querySelectorAll('.js-reveal')).forEach((el) => {
        revealItemsObserver.unobserve(el);
    });
    window.removeEventListener('resize', onResize);
}

const _module = { init, destroy };

export default _module;
